body {
  font-family: Helvetica, Arial, sans-serif;
}
h1,h2,h3 {
  margin: 0.5rem 0;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
}
h3 {
  font-size: 1rem;
}
a {
  text-decoration: none;
}
.row {
  display: flex;
  justify-content: space-between;
}
.col-1 {
  flex: 1
}
.col-2 {
  flex: 2
}
.block {
  background-color: #e0e0e0;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  color: black;
}
.row.center {
  align-items: center;
}
img.small {
  max-height: 8rem;
}
button {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.5rem;
  border: 0.1rem #404040 solid;
  background-color: orange;
  width: 100%;
  cursor: pointer;

}
button.add {
  background-color: #40c0f0;
  width: 1.5rem;
}
button.remove {
  background-color: #f04040;
  width: 1.5rem;
}
button.badge {
  background-color: #f04040;
  border: none;
  color: #ffffff;
  width: 1.5rem;
}
text-right {
  text-align: right;
}
.slider {
  width:500;
  height:300;
}

/* Responsive layout */
@media (max-width: 580px) {
  .flex-stack {
      display: flex;
      flex: 3; 
      flex-direction: column;
      align-items: center;
  }
}